import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/Footer';

export const footerData = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      dGraphQl {
        nodeQuery(filter: { conditions: { field: "title", value: "Footer" } }) {
          entities {
            ... on DrupalGraphQl_NodeFooter {
              fieldCompanyInformation {
                value
                processed
              }
              uuid
              fieldFooterLink {
                url {
                  path
                }
                title
              }
              fieldLogoSvg {
                alt
                title
                entity {
                  url
                }
              }
              # fieldText {
              #   value
              # }
              fieldFollowUs {
                entity {
                  ... on DrupalGraphQl_ParagraphFollowus {
                    fieldLinkedin
                    fieldInstagram
                    fieldFacebook
                    fieldTitle
                    fieldXing
                    fieldYoutube
                    fieldVkontakte
                  }
                }
              }
              fieldMapLink {
                url {
                  path
                }
              }
              fieldLogoLink {
                url {
                  path
                }
              }
              fieldMapText1
              fieldMapText2
              fieldTextFooterInfo {
                value
              }
              fieldTextFooterInfo2 {
                value
              }
            }
          }
        }
      }
    }
  `);

  return transformers.footerTransform(data.dGraphQl);
};
