import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/ExternalLinksPopup';

export const externalLinksPopupData = () => {
  const data = useStaticQuery(graphql`
    query externalLinksPopupQuery {
      dGraphQl {
        nodeQuery(filter: {conditions: {field: "title", value: "Footer"}}) {
          entities {
            ... on DrupalGraphQl_NodeFooter {
              uuid
              fieldPopUp {
                entity {
                  ... on DrupalGraphQl_ParagraphPopUpSaying {
                    entityBundle
                    fieldLinkCancel
                    fieldLinkOk
                    fieldLongText {
                      processed
                      value
                    }
                    fieldTitle
                    uuid
                  }
                }
              }
              fieldWebsiteWhitelist
            }
          }
        }
      }
    }
  `);

  return transformers.externalLinksPopupTransform(data.dGraphQl);
}


