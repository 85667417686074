import React, { useEffect, useState } from 'react';

// === Content === //
import { hcpHonestyPopupData } from '@graphql/HcpHonestyPopup';
import closeIcon from '@assets/svg/close_red.svg';

// === Components === //
import {
  Button,
  ButtonLink,
  PopupClose,
  PopupContent,
  PopupFooterInner,
  PopupFooterWrapper,
  PopupHeader,
  PopupInner,
  PopupWrapper,
} from '@components/layout/ExternalLinksPopup';

// === Helpers === //
import Cookies from 'js-cookie';
import * as utils from '@utils';

// === Types === //
import { HonestyPopupData } from '@content/types/layout';

interface HonestyPopupProps {
  pageUrl: string;
}

const HonestyPopup = ({ pageUrl }: HonestyPopupProps) => {
  const data: HonestyPopupData = hcpHonestyPopupData();
  const [open, setOpen] = useState<boolean>(false);
  const [href, setHref] = useState<string>('#');

  const typeFromCookies = Cookies.get('rethinkFabry-view');

  const closePopup = () => {
    setOpen(false);
    setHref('#');
  };

  const setExternalLinkFunc = () => {
    if (typeFromCookies !== 'hcp') {
      const allLinks = document.querySelectorAll('a');

      [...(allLinks as any)].forEach((link) => {
        const href = link.getAttribute('href');

        if (href && href.includes('/hcp')) {
          link.addEventListener('click', (e) => {
            e.preventDefault();

            setOpen(true);
            setHref(link.href);
          });
        }
      });

      if (pageUrl.includes('/hcp')) {
        setOpen(true);
        setHref(pageUrl);
      }
    }
  };

  const handleDecline = () => {
    if (pageUrl.includes('/hcp')) {
      if (typeof window !== 'undefined') {
        window.location.href = '/';
      }
      closePopup();
    } else {
      closePopup();
    }
  };

  useEffect(() => {
    setExternalLinkFunc();
  }, []);

  return (
    <PopupWrapper open={open}>
      <PopupInner open={open}>
        <PopupHeader>
          <span>{data.title}</span>
          <PopupClose src={closeIcon} onClick={handleDecline} />
        </PopupHeader>
        <PopupContent>{utils.SafeHtml(data.copy)}</PopupContent>
        <PopupFooterWrapper>
          <PopupFooterInner>
            <ButtonLink
              href={href}
              onClick={() => {
                Cookies.set('rethinkFabry-view', 'hcp', { expires: 365 });
                Cookies.set('rethinkFabry-honesty', 'true', { expires: 365 });
                setTimeout(() => {
                  closePopup();
                }, 200);
              }}
            >
              {data.buttonAccept}
            </ButtonLink>
            <Button type="button" onClick={handleDecline}>
              {data.buttonDecline}
            </Button>
          </PopupFooterInner>
        </PopupFooterWrapper>
      </PopupInner>
    </PopupWrapper>
  );
};

export default HonestyPopup;
