import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/HCPFormPopup';

export const hcpFormPopupData = () => {
  const data = useStaticQuery(graphql`
    query hcpFormPopupData {
      dGraphQl {
        nodeQuery(filter: { conditions: { field: "title", value: "Footer" } }) {
          entities {
            ... on DrupalGraphQl_NodeFooter {
              fieldVali {
                entity {
                  ... on DrupalGraphQl_ParagraphValidationForm {
                    fieldApiEndpoint {
                      url {
                        path
                      }
                    }
                    fieldCheckInRespProfId
                    fieldCheckInRespRegistered
                    id
                    fieldTitle
                    fieldSubmit
                    fieldLabel
                    fieldPlaceholder
                    fieldErrorMessageTooMany
                    fieldErrorMessageTooLittle
                    fieldErrorMessageNumberError
                    fieldErrorMessageIsEmpty
                    fieldErrorMessageIncorrect
                    fieldDescription
                    fieldNumberLength
                    fieldIncludeOnPages {
                      entity {
                        entityLabel
                        path {
                          alias
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.hcpFormPopupTransform(data.dGraphQl);
};
