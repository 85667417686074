import React from 'react';

// === Components === //
import { Link } from 'gatsby';

// === Content === //
import logo from '@assets/svg/logo.svg';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions, respondFrom, respondTo } from '@styles';

// === Types === //
import { NavigationData, Variant } from '@content/types/layout';

const LogoWrapper = styled.div<LogoWrapperProps>`
  position: relative;
  width: 135px;
  margin-right: 10px;
  z-index: 10;

  ${respondFrom(
    breakpoints.md,
    css`
      width: 190px;
    `
  )};

  &::before {
    content: '';
    position: absolute;
    top: 73px;
    left: calc(-100vw - 75px);
    width: calc(100vw + 65px);
    height: 7px;
    background-color: ${(props) =>
      props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};

    ${respondTo(
      breakpoints.md,
      css`
        display: none;
      `
    )};
  }

  &::after {
    content: '';
    position: absolute;
    top: 56px;
    right: calc(-100vw - 60px);
    width: calc(100vw + 65px);
    height: 7px;
    background-color: ${(props) =>
      props.variant === 'blue' ? colors.ui.hcpDefault : colors.ui.patientDefault};

    ${respondFrom(
      breakpoints.md,
      css`
        top: 73px;
      `
    )};
  }
`;

const LogoInner = styled.div`
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const LogoHomeText = styled.p`
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin: auto;
  font-weight: 700;
  font-size: ${dimensions.fontSize.small}px;
  text-transform: uppercase;
  color: ${colors.transparentLight};
  text-align: center;
  transform: translateX(-25px);

  ${respondTo(
    breakpoints.md,
    css`
      display: none;
    `
  )};
`;

const LogoTargetText = styled.p<LogoTargetProps>`
  display: inline-block;
  background-color: ${(props) =>
    props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${dimensions.fontSize.small}px;
  padding: 5px 10px;
  border-radius: 15px;
  margin-top: 5px;
  width: max-content;
  position: relative;
  z-index: 1;

  ${respondTo(
    breakpoints.md,
    css`
      display: none;
    `
  )};
`;

const LogoImg = styled.img`
  width: 190px;
`;

interface NavigationLogoProps {
  data: NavigationData;
  variant: Variant;
}

interface LogoWrapperProps {
  variant: Variant;
}

interface LogoTargetProps {
  variant: Variant;
}

const NavigationLogo = ({ data, variant }: NavigationLogoProps) => {
  return (
    <LogoWrapper variant={variant}>
      <LogoInner>
        <LogoHomeText>{data.home}</LogoHomeText>
        <Link to={variant === 'blue' ? '/' : '/hcp'}>
          <LogoImg src={data.logo && data.logo !== '' ? data.logo : logo} alt="" />
        </Link>
        <LogoTargetText variant={variant}>{data.target}</LogoTargetText>
      </LogoInner>
    </LogoWrapper>
  );
};

export default NavigationLogo;
