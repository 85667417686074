import React from 'react';

// === Components === //
import Container from '@components/common/Container';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors, dimensions, respondTo, breakpoints } from '@styles';
import FooterLinks from './FooterLinks';

const FooterBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${respondTo(
    breakpoints.md,
    css`
      flex-direction: column;
      justify-content: center;
    `
  )}
`;

const MapContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 35px;
  ${respondTo(
    breakpoints.lg,
    css`
      justify-content: center;
      padding-bottom: 15px;
    `
  )}
`;

const TextBox = styled.p`
  color: ${colors.footerMap};
  font-size: ${dimensions.fontSize.regular}px;
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 15px;
`;

const MapBox = styled.a`
  display: flex;
  flex-direction: row;
  border: 4px solid rgba(112, 112, 112, 0.26);
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  text-decoration: none;
`;

const Map = styled.img`
  width: 128px;
`;

const Text = styled.div`
  color: ${colors.footerMap};
  font-size: ${dimensions.fontSize.regular}px;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 10px;
  max-width: 110px;

  > span {
    display: block;
  }
`;

const MapTextBottom = styled.div`
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 400;
  ${respondTo(
    breakpoints.lg,
    css`
      text-align: center;
    `
  )}
`;

const FooterBottom = ({ data, variant, pageType }) => {
  return (
    <Container>
      <FooterBottomWrapper>
        <ContentWrapper>
          <MapContent className="map-contnet">
            <TextBox>{data.map_text}</TextBox>
            <MapBox href={data.chiesi_map_href} target="_blank">
              <Map src={data.map} />
              <Text>
                <span>{utils.SafeHtml(data.map_text_inside)}</span>
              </Text>
            </MapBox>
          </MapContent>
          <FooterLinks links={data.links} variant={variant} />
        </ContentWrapper>
        {pageType === 'patient_page' && data.map_text_bottom2 !== '' ? (
          <MapTextBottom>{utils.SafeHtml(data.map_text_bottom2)}</MapTextBottom>
        ) : (
          <MapTextBottom>{utils.SafeHtml(data.map_text_bottom)}</MapTextBottom>
        )}
      </FooterBottomWrapper>
    </Container>
  );
};

export default FooterBottom;
