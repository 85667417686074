import React, { useEffect, useState } from 'react';

// === Content === //
import { externalLinksPopupData } from '@graphql/ExternalLinksPopup';
import closeIcon from '@assets/svg/close_red.svg';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { colors, dimensions, fonts, mixins } from '@styles';

// === Types === //
import { ExterlanLinksData } from '@content/types/layout';

export const PopupWrapper = styled.div<PopupProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.transparentDark};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  ${mixins.transitionDefault};
`;

export const PopupInner = styled.div<PopupProps>`
  max-width: 673px;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 8px;
  overflow: hidden;
  color: ${colors.text.default};
`;

export const PopupHeader = styled.div`
  position: relative;
  padding: 16px;
  background-color: ${colors.ui.accordionBodyBg};

  > span {
    font-weight: 700;
    display: block;
    padding-right: 30px;
  }
`;

export const PopupContent = styled.div`
  padding: 16px;

  p {
    margin: 0;
  }
`;

export const PopupFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  background-color: ${colors.ui.accordionBodyBg};
`;

export const PopupFooterInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  display: block;
  box-shadow: none;
  outline: none;
  background-color: ${colors.ui.hcpDark};
  border: none;
  border-radius: 99px;
  color: ${colors.white};
  font-size: ${dimensions.fontSize.small}px;
  padding: 9px 30px;
  margin: 0 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: ${fonts.default};
  cursor: pointer;
  ${mixins.transitionDefault};

  &:hover {
    background-color: ${colors.ui.hcpDefault};
  }
`;

export const ButtonLink = styled.a`
  display: block;
  text-decoration: none;
  background-color: ${colors.ui.accept};
  border: none;
  border-radius: 99px;
  color: ${colors.white};
  font-size: ${dimensions.fontSize.small}px;
  padding: 9px 30px;
  margin: 0 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: ${fonts.default};
  cursor: pointer;
  ${mixins.transitionDefault};

  &:hover {
    background-color: ${colors.ui.acceptDark};
  }
`;

export const PopupClose = styled.img`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  transition: transform 1s;
  width: 20px;

  &:hover {
    transform: rotateZ(180deg);
  }
`;

interface PopupProps {
  open: boolean;
}

const ExternalLinksPopup = () => {
  const data: ExterlanLinksData = externalLinksPopupData();
  const [open, setOpen] = useState<boolean>(false);
  const [href, setHref] = useState<string>('#');
  const [target, setTarget] = useState<string>('_self');

  const closePopup = () => {
    setOpen(false);
    setHref('#');
    setTarget('_self');
  };

  const setExternalLinkFunc = () => {
    const allLinks = document.querySelectorAll('a');

    [...(allLinks as any)].forEach((link) => {
      const href = link.getAttribute('href');
      const whitelist = data.whitelist;

      if (whitelist.find((url) => href && href.includes(url))) {
        return;
      }

      if (
        href &&
        (link.target === '_blank' ||
          href.includes('https://') ||
          href.includes('http://') ||
          href.includes('www.'))
      ) {
        link.addEventListener('click', (e) => {
          e.preventDefault();

          setOpen(true);
          setHref(link.href);
          setTarget(link.target ? link.target : '_self');
        });
      }
    });
  };

  useEffect(() => {
    setExternalLinkFunc();
  }, []);

  return (
    <PopupWrapper open={open}>
      <PopupInner open={open}>
        <PopupHeader>
          <span>{data.title}</span>
          <PopupClose src={closeIcon} onClick={closePopup} />
        </PopupHeader>
        <PopupContent>{utils.SafeHtml(data.content)}</PopupContent>
        <PopupFooterWrapper>
          <PopupFooterInner>
            <ButtonLink
              href={href}
              target={target}
              onClick={() => {
                setTimeout(() => {
                  closePopup();
                }, 200);
              }}
            >
              {data.ok}
            </ButtonLink>
            <Button type="button" onClick={closePopup}>
              {data.cancel}
            </Button>
          </PopupFooterInner>
        </PopupFooterWrapper>
      </PopupInner>
    </PopupWrapper>
  );
};

export default ExternalLinksPopup;
