import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/HCPHonestyPopup';

export const hcpHonestyPopupData = () => {
  const data = useStaticQuery(graphql`
    query hcpHonestyPopupQuery {
      dGraphQl {
        nodeQuery(filter: {conditions: {field: "title", value: "Footer"}}) {
          entities {
            ... on DrupalGraphQl_NodeFooter {
              uuid
              fieldPopUp2 {
                entity {
                  ... on DrupalGraphQl_ParagraphPopUpSaying2 {
                    entityBundle
                    fieldActive
                    fieldLinkCancel
                    fieldLinkOk
                    fieldLongText {
                      processed
                    }
                    fieldTitle
                    uuid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.hcpHonestyPopup(data.dGraphQl);
}


