import { dimensions, fonts, colors, breakpoints } from './variables';
import { css } from '@emotion/react';
import { fluidRange, respondFrom } from '@styles';
import { localizationsExceptions } from './localizations-exceptions'

export const normalize = css`
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-size: ${dimensions.fontSize.regular}px !important;
    line-height: ${dimensions.lineHeight.regular} !important;
  }

  body {
    width: 100%;
    min-width: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: ${fonts.default};
    font-weight: 400;
    color: ${colors.text.default};
    background-color: ${colors.white};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin: 0;
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: ${dimensions.lineHeight.heading};
    text-rendering: optimizeLegibility;
  }

  h1 {
    ${fluidRange('fontSize', '17px', '45px')};

    sup {
      ${fluidRange('fontSize', '12px', '25px')};
      ${fluidRange('top', '-6px', '-18px')};
    }
  }

  h2 {
    ${fluidRange('fontSize', '16px', '20px')};
    
  }

  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  sup,
  sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
    font-size: ${dimensions.fontSize.small}px
  }
  sub {
    top: 0.4em;
  }

  /* global classes */
  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-align-center {
    text-align: center;
  }

  ${localizationsExceptions};
`;