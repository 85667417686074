// === Helpers === //
import get from 'lodash.get';
import * as utils from '@utils';

// === Types === //
import { TaxonomyTermQueryProps } from '@content/types/query';
import { MenuData } from '@content/types/layout';

const compare = (a, b) => {
  if (a.weight < b.weight) {
    return -1;
  }
  if (a.weight > b.weight) {
    return 1;
  }
  return 0;
};

export const hcpNavigationTransform = (query) =>  {
  const startPageQueryData = query.startPage.entities[0];
  const pages = get(query, 'pages.entities', []);
  const taxonomyTermQueryData = get(query, 'taxonomyTermQuery.entities', []);
  const homepageMenuItem = taxonomyTermQueryData && taxonomyTermQueryData.length > 0
    ? taxonomyTermQueryData[0]
    : null;
  const navItems: TaxonomyTermQueryProps[] = [];

  taxonomyTermQueryData && taxonomyTermQueryData.length > 0
    ? taxonomyTermQueryData.forEach((item, index) => {
    if (index !== 0) {
      navItems.push(item);
    }
  }) : [];

  const data = {
    home: homepageMenuItem ? get(homepageMenuItem, 'name', '') : '',
    target: get(startPageQueryData, 'fieldPatientInfo', ''),
    menuLabel: get(startPageQueryData, 'fieldMobileMenuTitle', ''),
    menu: navItems.filter(item => item !== null).sort(compare).map(item => {
      const page = pages.find(page => get(page, 'fieldHeaderMenu.entity.uuid', '') === item.uuid);
      const href = page ? `${get(page, 'path.alias', '')}` : '/hcp';
      const submenu: MenuData[] = [];

      get(page, 'fieldSection', []).forEach(section => {
        if (section && section.entity && section.entity.fieldSubmenuName && section.entity.fieldSubmenuName !== '') {
          const submenuItem = {
            label: get(section, 'entity.fieldSubmenuName', ''),
            href: `${href}#${utils.slugify(get(section, 'entity.fieldAnchorId') || get(section, 'entity.fieldSubmenuName', ''))}`,
            uuid: get(section, 'entity.uuid'),
          }

          submenu.push(submenuItem);
        }
      });
      
      return {
        label: get(item, 'name', ''),
        href,
        uuid: get(page, 'uuid', ''),
        menu: page && submenu && submenu.length > 0 ? submenu : undefined
      }
    }),
    switchVersion: {
      text: get(startPageQueryData, 'fieldPatientsSwichText.value', ''),
      href: '/'
    },
    language: get(startPageQueryData, 'fieldLanguage', undefined) ? {
      title: get(startPageQueryData, 'fieldLanguage.entity.fieldLanguageCode', ''),
      icon: get(startPageQueryData, 'fieldLanguage.entity.fieldFlag', undefined) ? {
        alt: get(startPageQueryData, 'fieldLanguage.entity.fieldFlag.alt', ''),
        title: get(startPageQueryData, 'fieldLanguage.entity.fieldFlag.title', ''),
        src: get(startPageQueryData, 'fieldLanguage.entity.fieldFlag.url', ''),
      } : undefined,
      link: get(startPageQueryData, 'fieldLanguage.entity.fieldLanguageDomain', '')
    } : undefined,
    logo: get(startPageQueryData, 'fieldLogoSvg.entity.url', ''),
  }

  return data;
}