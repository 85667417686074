import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/Settings';

export const settingsData = () => {
  const data = useStaticQuery(graphql`
    query settingsQuery {
      dGraphQl {
        nodeQuery(filter: {conditions: {field: "title", value: "Footer"}}) {
          entities {
            ... on DrupalGraphQl_NodeFooter {
              fieldOnetrustId
              fieldGtmId
              fieldMatomoUrl
              fieldIdsite
            }
          }
        }
      }
    }
  `);

  return transformers.settingsTransform(data.dGraphQl);
};
