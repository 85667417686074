import get from 'lodash.get';

export const externalLinksPopupTransform = (query) =>  {
  const queryData = query.nodeQuery.entities[0];

  const data = {
    cancel: get(queryData, 'fieldPopUp.entity.fieldLinkCancel', ''),
    ok: get(queryData, 'fieldPopUp.entity.fieldLinkOk', ''),
    content: get(queryData, 'fieldPopUp.entity.fieldLongText.processed', ''),
    title: get(queryData, 'fieldPopUp.entity.fieldTitle', ''),
    whitelist: get(queryData, 'fieldWebsiteWhitelist', [])
  };

  return data;
}