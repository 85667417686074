import React from 'react';
import { Link } from 'gatsby';

import { FooterLinksData, Variant } from '@content/types/layout';
import { breakpoints, colors, dimensions, mixins, respondTo } from '@styles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const LinksBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  ${respondTo(
    breakpoints.lg,
    css`
      align-items: center;
      order: 3;
    `
  )}
`;

const LinksBox = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;

  ${respondTo(
    breakpoints.md,
    css`
      flex-flow: column;
      justify-content: center;

      > li {
        margin: 10px 0;
        text-align: center;
      }
    `
  )};
`;

const FooterLink = styled(Link)`
  font-size: ${dimensions.fontSize.lg_md}px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 10px;
  color: ${colors.footerText};
  ${mixins.transitionDefault};

  &:hover {
    color: ${colors.ui.patientDefault};
  }

  &.variant--red {
    &:hover {
      color: ${colors.ui.hcpDefault};
    }
  }
`;

interface FooterLinksProps {
  links: FooterLinksData[];
  variant: Variant;
}

const FooterLinks = ({ links, variant }: FooterLinksProps) => {
  return (
    <LinksBoxWrapper className="links-box-wrapper">
      <LinksBox className="links-box">
        {links.map((item, index) => (
          <li key={index}>
            <FooterLink
              to={variant === 'blue' ? item.href : `/hcp${item.href}`}
              className={`variant--${variant}`}
            >
              {item.label}
            </FooterLink>
          </li>
        ))}
      </LinksBox>
      {/* the data is downloaded from the CMS but the client decided to hide this field for the moment  */}
      {/* {pageType === 'start_page' && <FooterLinksText>{data.link_text}</FooterLinksText>} */}
    </LinksBoxWrapper>
  );
};

export default FooterLinks;
