import React from 'react';

// === Helpers === //
import Cookies from 'js-cookie';
import * as utils from '@utils';

// === Components === //
import { Link } from 'gatsby';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { animation, breakpoints, colors, dimensions, respondFrom, respondTo } from '@styles';

// === Types === //
import { SwitchVersionData, Variant } from '@content/types/layout';

const SwitchWrapper = styled.div`
  padding: 0 5px;

  ${respondTo(
    breakpoints.lg,
    css`
      margin-top: 10px;
      width: 100%;
      padding: 0;
    `
  )};
`;

const SwitchLink = styled(Link)`
  display: block;
  background-color: ${colors.ui.patientDark};
  border-radius: 30px;
  padding: 4px;
  text-decoration: none;
  backdrop-filter: blur(21.7199px);

  ${respondTo(
    breakpoints.lg,
    css`
      padding: 3px 0;
      border-radius: 0;
      background-color: ${colors.transparentLight};
    `
  )};

  &.variant--red {
    background-color: ${colors.ui.hcpDark};

    ${respondTo(
      breakpoints.lg,
      css`
        background-color: ${colors.transparentLight};
      `
    )};
  }
`;

const SwitchInner = styled.div<SwitchInnerProps>`
  padding: ${(props) => (props.variant === 'blue' ? '5px 15px 5px 40px' : '5px 40px 5px 15px')};
  background-color: ${(props) =>
    props.variant === 'blue' ? colors.ui.hcpDefault : colors.ui.patientDefault};
  border-radius: 30px;
  display: flex;
  flex-flow: row;
  position: relative;

  ${respondTo(
    breakpoints.lg,
    css`
      border-radius: 0;
      padding: 15px;
    `
  )};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => (props.variant === 'blue' ? '4px' : 'auto')};
    right: ${(props) => (props.variant === 'red' ? '4px' : 'auto')};
    width: 27px;
    height: 27px;
    background-color: ${colors.white};
    border-radius: 100%;
    margin: auto;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.53);
    transform: translateX(0);

    ${respondTo(
      breakpoints.lg,
      css`
        display: none;
      `
    )};
  }

  /* &:hover {
    &::before {
      transform: ${(props) =>
    props.variant === 'blue' ? 'translateX(-100%)' : 'translateX(100%)'};
      left: ${(props) => (props.variant === 'blue' ? 'calc(100% - 4px)' : 'auto')};
      right: ${(props) => (props.variant === 'red' ? 'calc(100% - 4px)' : 'auto')};
      transition: all ease-in-out ${animation.slow}ms;
    }
  } */
`;

const GoToText = styled.div`
  span {
    display: inline-block;
    color: ${colors.white};
    font-size: ${dimensions.fontSize.tiny}px;
    text-transform: uppercase;

    &:nth-of-type(1) {
      margin-right: 5px;
      min-width: 40px;
    }
    &:nth-of-type(2) {
      font-weight: 700;
      min-width: 95px;
      width: fit-content;
      text-align: left;
      text-shadow: 0px 4px 6px ${colors.transparentDark};
    }

    ${respondTo(
      breakpoints.lg,
      css`
        display: none;
      `
    )};
  }

  > div {
    display: flex;
  }
`;

const TextMobile = styled.div`
  color: ${colors.white};
  font-size: 15px;
  text-transform: uppercase;

  span {
    margin-right: 5px;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      display: none;
    `
  )};
`;

interface NavigationSwitchProps {
  variant: Variant;
  data: SwitchVersionData;
  changeVariant: boolean;
}

interface SwitchInnerProps {
  variant: Variant;
}

const NavigationSwitch = ({ variant, data, changeVariant }: NavigationSwitchProps) => {
  return (
    <>
      {data?.text && data?.text !== '' && (
        <SwitchWrapper className="navigation-switch">
          <SwitchLink
            to={data.href}
            className={`variant--${variant} navigation-switch-link`}
            onClick={() => {
              if (changeVariant) {
                Cookies.set('rethinkFabry-view', variant === 'red' ? 'patients' : 'hcp', {
                  expires: 365,
                });
              } else {
                if (variant === 'red')
                  Cookies.set('rethinkFabry-view', 'patients', { expires: 365 });
              }
            }}
          >
            <SwitchInner variant={variant} className="navigation-switch-inner">
              <GoToText className="navigation-switch-go">{utils.SafeHtml(data.text)}</GoToText>
              <TextMobile>{utils.SafeHtml(data.text)}</TextMobile>
            </SwitchInner>
          </SwitchLink>
        </SwitchWrapper>
      )}
    </>
  );
};

export default NavigationSwitch;
