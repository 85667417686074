import React from 'react';

// === Components ===  //
import FooterTop from '@components/layout/FooterTop';
import FooterBottom from '@components/layout/FooterBottom';

// === Content === //
import { footerData } from '@graphql/Footer';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, respondFrom } from '@styles';

// === Types === //
import { PageType, Variant } from '@content/types/layout';

interface FooterProps {
  variant: Variant;
  pageType: PageType;
  bottomSpace: boolean;
}

const FooterWrapper = styled.div`
  &.subpage {
    margin-top: 48px;

    ${respondFrom(
      breakpoints.md,
      css`
        margin-top: 80px;
      `
    )}
    ${respondFrom(
      breakpoints.lg,
      css`
        margin-top: 104px;
      `
    )}
  }
`;

const Footer = ({ variant, pageType, bottomSpace }: FooterProps) => {
  const data = footerData();

  return (
    <FooterWrapper className={bottomSpace ? 'subpage' : ''}>
      <FooterTop data={data} pageType={pageType} variant={variant} />
      {pageType !== 'start_page' && (
        <FooterBottom data={data} variant={variant} pageType={pageType} />
      )}
    </FooterWrapper>
  );
};

export default Footer;
