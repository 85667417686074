import React from 'react';

// === Components === //
import Helmet from 'react-helmet';

// === Content === //
import { settingsData } from '@graphql/Settings';

// === Types === //
import { MetaData } from '@content/types/basic';

interface MetaGlobalProps {
  metaData: MetaData;
}

const MetaGlobal = ({ metaData }: MetaGlobalProps) => {
  const settings = settingsData();
  const oneTrustByCountries = ['cz', 'es'];

  return (
    <>
      <Helmet
        defer={false}
        defaultTitle="Rethink Fabry"
        htmlAttributes={{ lang: process.env.RETHINK_FABRY_LANGUAGE }}
        bodyAttributes={{
          class: `lang-${process.env.RETHINK_FABRY_LANGUAGE}`,
        }}
      >
        {settings?.oneTrust &&
          settings?.oneTrust !== '' &&
          oneTrustByCountries.find((country) => country === process.env.RETHINK_FABRY_LANGUAGE) && (
            <script
              type="text/javascript"
              src={`https://cdn.cookielaw.org/consent/${settings.oneTrust}/OtAutoBlock.js`}
            ></script>
          )}
        {settings?.oneTrust && settings?.oneTrust !== '' && (
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={`${settings.oneTrust}`}
          ></script>
        )}
        {settings?.oneTrust && settings?.oneTrust !== '' && (
          <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
        )}
        {!!settings?.matomo?.siteId && !!settings?.matomo?.url && (
          <script>
            {`
            var _paq = window._paq = window._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
              var u="//${settings.matomo.url}/";
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '${settings.matomo.siteId}']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();
          `}
          </script>
        )}

        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;0,700;1,100;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        ></link>

        {settings?.GTM && settings?.GTM !== '' && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${settings.GTM}');
        `}</script>
        )}
      </Helmet>
      {settings?.GTM && settings?.GTM !== '' && (
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=${settings.GTM}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      )}
    </>
  );
};

export default MetaGlobal;
