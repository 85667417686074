import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/PatientNavigation';

export const patientsNavigationData = () => {
  const data = useStaticQuery(graphql`
    query patientsNavigationQuery {
      dGraphQl {
        taxonomyTermQuery(filter: {conditions: {field: "vid", value: "patient_site"}}) {
          entities {
            ... on DrupalGraphQl_TaxonomyTermPatientSite {
              uuid
              name
              weight
              entityLabel
            }
          }
        }
        pages: nodeQuery(filter: {conditions: [{field: "type", value: "patient_page"}]}) {
          entities {
            ... on DrupalGraphQl_NodePatientPage {
              entityLabel
              uuid
              path {
                alias
              }
              fieldHeaderMenu {
                entity {
                  ... on DrupalGraphQl_TaxonomyTermPatientSite {
                    uuid
                  }
                }
              }
              fieldSection {
                entity {
                  ... on DrupalGraphQl_ParagraphSection {
                    fieldSubmenuName
                    fieldAnchorId
                    uuid
                  }
                }
              }
            }
          }
        }
        startPage: nodeQuery(filter: {conditions: {field: "type", value: "start_page"}}) {
          entities {
            ... on DrupalGraphQl_NodeStartPage {
              fieldHtcInfo
              fieldPatientInfo
              fieldHcpSwichText {
                value
              }
              fieldPatientsSwichText {
                value
              }
              fieldMobileMenuTitle
              fieldLanguage {
                entity {
                  ...DrupalGraphQlParagraphLanguageSwitch
                }
              }
              fieldLogoSvg {
                alt
                title
                entity {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.patientsNavigationTransform(data.dGraphQl);
}


