import get from 'lodash.get';

export const hcpHonestyPopup = (query) =>  {
  const queryData = get(query, 'nodeQuery.entities[0]', undefined);

  const data = {
    title: get(queryData, 'fieldPopUp2.entity.fieldTitle', ''),
    copy: get(queryData, 'fieldPopUp2.entity.fieldLongText.processed', ''),
    buttonAccept: get(queryData, 'fieldPopUp2.entity.fieldLinkOk', ''),
    buttonDecline: get(queryData, 'fieldPopUp2.entity.fieldLinkCancel', ''),
    active: get(queryData, 'fieldPopUp2.entity.fieldActive', [])[0] === '1' ? true : false
  }

  return data;
}