import get from 'lodash.get';

export const settingsTransform = (query) =>  {
  const queryData = query.nodeQuery;

  const data = {
    GTM: get(queryData, 'entities[0].fieldGtmId', ''),
    oneTrust: get(queryData, 'entities[0].fieldOnetrustId', ''),
    matomo: {
      url: get(queryData, 'entities[0].fieldMatomoUrl', ''),
      siteId: get(queryData, 'entities[0].fieldIdsite', ''),
    },
  }

  return data;
}