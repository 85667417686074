import { dimensions, fonts, colors, breakpoints } from './variables';
import { css } from '@emotion/react';
import { fluidRange, respondFrom, respondTo } from '@styles';

export const localizationsExceptions = css`
  .lang-se,
  .lang-fi,
  .lang-gr {
    .social-icons {
      display: none;
    }
  }

  .lang-se,
  .lang-fi,
  .lang-gr,
  .lang-dk,
  .lang-no {
    .image-full {
      img {
        max-width: 100% !important;
        width: auto !important;
      }
    }
  }

  .lang-se,
  .lang-fi,
  .lang-gr,
  .lang-dk {
    .navigation-switch {
      &-inner {
        ${respondFrom(
          breakpoints.lg,
          css`
            height: 36px;
            align-items: center;
          `
        )}
      }
  
      &-go {
        span {
          &:nth-of-type(2) {
            width: 115px !important;
            text-align: center !important;
          }
        }
      }
    }
  }

  .lang-se,
  .lang-fi,
  .lang-gr,
  .lang-no,
  .lang-ee {
    .hero-button {
      max-width: none !important;
      width: 355px !important;
      height: 92px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
  
      ${respondFrom(
        breakpoints.md,
        css`
          width: 380px !important;
        `
      )}
  
      ${respondFrom(
        breakpoints.lg,
        css`
          width: 430px !important;
          height: 134px !important;
        `
      )}
    }
  }

  .lang-dk {
    .hero-button {
      max-width: none !important;
      width: 355px !important;
      height: 92px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
  
      ${respondFrom(
        breakpoints.md,
        css`
          width: 400px !important;
        `
      )}
  
      ${respondFrom(
        breakpoints.lg,
        css`
          width: 460px !important;
          height: 134px !important;
        `
      )}
    }
  }

  .lang-ru {
    .hero-button {
      max-width: none !important;
      width: 355px !important;
      height: 120px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
  
      ${respondFrom(
        breakpoints.md,
        css`
          width: 380px !important;
          height: 140px !important;
        `
      )}
  
      ${respondFrom(
        breakpoints.lg,
        css`
          width: 430px !important;
          height: 140px !important;
        `
      )}
    }
  }

  .lang-nl {
    .hero-button {
      max-width: none !important;
      width: 370px !important;
      height: 120px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
  
      ${respondFrom(
        breakpoints.md,
        css`
          width: 480px !important;
          height: 140px !important;
        `
      )}
  
      ${respondFrom(
        breakpoints.lg,
        css`
          width: 500px !important;
          height: 140px !important;
        `
      )}
    }
  }

  .lang-fi,
  .lang-ru {
    .map-contnet {
      flex-flow: column;

      ${respondFrom(
        breakpoints.lg,
        css`
          flex-flow: row;
        `
      )}
    }
  }

  .lang-cz,
  .lang-at,
  .lang-si
   {
    .no-border {
      .links-box {
        flex-flow: column;

        > li {
          ${respondFrom(
            breakpoints.md,
            css`
              text-align: center;
            `
          )}
        }
      }
    }
  }

  .lang-cz,
  .lang-at,
  .lang-si {
    .links-box {
      flex-flow: column;

      > li {
        ${respondFrom(
          breakpoints.md,
          css`
            text-align: right;
          `
        )}
      }
    } 
  }

  .lang-sr,
  .lang-fr,
  .lang-dk,
  .lang-no,
  .lang-rs,
  .lang-pl,
  .lang-ru,
  .lang-nl {
    .links-box {
      ${respondTo(
        940,
        css`
          flex-flow: column;
        `
      )};

      > li {
        ${respondFrom(
          breakpoints.md,
          css`
            text-align: right;
          `
        )}
      }
    }
  }
`