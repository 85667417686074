import React from 'react';

// === Components === //
import { Link } from 'gatsby';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions, respondFrom } from '@styles';

// === Types === //
import { LanguageData, Variant } from '@content/types/layout';

const Wrapper = styled.div`
  width: 100%;
  margin: 10px 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-left: 23px;
    `
  )}
`;

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 11px;
  color: ${colors.white};
  text-decoration: none;
  font-size: ${dimensions.fontSize.small}px;

  &:hover {
    ${respondFrom(
      breakpoints.lg,
      css`
        text-decoration: underline;
      `
    )}
  }
`;

const Label = styled.span`
  display: block;
  max-width: 80px;
`;

interface LanguageSelectorProps {
  variant: Variant;
  lang: LanguageData;
}

const LanguageSelector = ({ lang, variant }: LanguageSelectorProps) => {
  return (
    <Wrapper>
      <CustomLink to={`${variant === 'red' ? '/hcp' : ''}${lang.link}`}>
        {lang.icon && lang.icon.src !== '' && (
          <img
            src={lang.icon?.src}
            alt={lang.icon?.alt}
            title={lang.icon?.title}
            width="18px"
            height="18px"
          />
        )}
        {lang.title && lang.title !== '' && <Label>{utils.SafeHtml(lang.title)}</Label>}
      </CustomLink>
    </Wrapper>
  );
};

export default LanguageSelector;
