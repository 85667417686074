import React, { Fragment } from 'react';

// === Styles === //
import styled from '@emotion/styled';
import { dimensions, mixins } from '@styles';

const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-betwen;
  padding-top: 20px;
`;

const SocialIconsText = styled.p`
  font-size: ${dimensions.fontSize.tiny}px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-top: 6px;
`;

const SocialIconsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-betwen;
  width: 100%;
`;

const SocialIconList = styled.img`
  width: 26px;
  height: 26px;
  margin: 0 15px;
  cursor: pointer;
  ${mixins.transitionDefault};

  &:hover {
    transform: scale(1.1);
  }
`;

const FooterSocialIcons = ({ data }) => {
  return (
    <SocialIconsWrapper className="social-icons">
      <SocialIconsText>{data.social_media_text}</SocialIconsText>
      <SocialIconsBox>
        {data.icons.map((item, index) => (
          <Fragment key={index}>
            {item.href && item.href !== '' && item.href !== '/' && (
              <a href={item.href} target="_blank">
                <SocialIconList src={item.icon} />
              </a>
            )}
          </Fragment>
        ))}
      </SocialIconsBox>
    </SocialIconsWrapper>
  );
};

export default FooterSocialIcons;
