import React, { useState, useEffect } from 'react';

// === Helpers === //
import Cookies from 'js-cookie';

// === Content === //
// import closeBlue from '@assets/svg/close_blue.svg';
// import closeRed from '@assets/svg/close_red.svg';
import { cookiesData } from '@graphql/Cookies';
import { hcpHonestyPopupData } from '@graphql/HcpHonestyPopup';
import { hcpFormPopupData } from '@graphql/HcpFormPopup';

// === Components === //
import MetaGlobal from '@components/layout/MetaGlobal';
import Navigation from '@components/layout/Navigation';
import Footer from '@components/layout/Footer';
// import CookieBanner from '@components/layout/CookieBanner';
import ExternalLinksPopup from '@components/layout/ExternalLinksPopup';
import HonestyPopup from '@components/layout/HonestyPopup';
import HcpFormPopup from '@components/layout/HcpFormPopup';

// === Styles === //
import { Global as StylesGlobal, css } from '@emotion/react';
import { normalize } from '@styles';
import styled from '@emotion/styled';

// === Types === //
import {
  CookieBannerData,
  HonestyPopupData,
  HcpFormPopupData,
  PageType,
  Variant,
} from '@content/types/layout';
import { MetaData } from '@content/types/basic';

const PageWrapper = styled.div`
  overflow-x: hidden;
`;

interface PageProps {
  children: React.ReactChild;
  variant: Variant;
  pageType: PageType;
  navUuid?: string;
  bottomSpace?: boolean;
  metaData: MetaData;
  pageUrl?: string;
}

const Page = ({
  children,
  variant,
  pageType,
  navUuid = undefined,
  bottomSpace = false,
  metaData,
  pageUrl = '/',
}: PageProps) => {
  // hcp validation form
  const [checkHcpForm, setCheckHcpForm] = useState<boolean>(false);
  const [hcpFormAcceptedPage, setHcpAcceptedPage] = useState<boolean>(false);
  // const [bannerClose, handleBannerClose] = useState(true);
  // const data: CookieBannerData = cookiesData();
  const honestyPopupData: HonestyPopupData = hcpHonestyPopupData();
  const formPopupData: HcpFormPopupData = hcpFormPopupData();
  const honestyPopupCookies = Cookies.get('rethinkFabry-honesty');

  // const cookieBannerClose = () => {
  //   if (Cookies.get('rethinkFabry-cookiesBanner')) {
  //     handleBannerClose(true);
  //   } else {
  //     handleBannerClose(false);
  //   }
  // };

  const triggerHcpFormValidation = () => {
    // check hcp form from cookies
    if (variant === 'red' && !!formPopupData.isExist) {
      const hcpFormFromCookies = Cookies.get('rizv-check');

      if (!hcpFormFromCookies || hcpFormFromCookies !== '1') {
        setCheckHcpForm(true);
      }
    }

    if (typeof window !== 'undefined') {
      const currentPage = window.location.pathname;

      // subfolder prefix workaround
      const urlsWithPrefix = !!process.env.RETHINK_FABRY_PATH_PREFIX
        ? formPopupData.includeOnPages.map((url) => process.env.RETHINK_FABRY_PATH_PREFIX + url)
        : [];

      const formAcceptedPagesFromDrupal = [...formPopupData.includeOnPages, ...urlsWithPrefix];
      const formAcceptedPages = [
        ...formAcceptedPagesFromDrupal,
        ...formAcceptedPagesFromDrupal.map((url) => url + '/'),
      ];

      if (!formAcceptedPages.find((page) => currentPage === page || currentPage === page + '/')) {
        setHcpAcceptedPage(true);
      }
    }
  };

  useEffect(() => {
    triggerHcpFormValidation();

    //   setTimeout(() => {
    //     cookieBannerClose();
    //   }, 1000);
  }, []);

  return (
    <PageWrapper>
      <MetaGlobal metaData={metaData} />
      <StylesGlobal styles={() => css(normalize)} />
      {pageType !== 'start_page' && pageType !== 'multiLanguages_page' && (
        <Navigation
          variant={variant}
          navUuid={navUuid}
          changeVariant={!honestyPopupData.active || honestyPopupCookies === 'true'}
        />
      )}
      {children}
      {pageType !== 'multiLanguages_page' && (
        <Footer variant={variant} pageType={pageType} bottomSpace={bottomSpace} />
      )}

      {/* Not needed, client have a OneTrust */}
      {/* <CookieBanner
        icon1={closeBlue}
        icon2={closeRed}
        title={data.title}
        text={variant === 'blue' ? data.textPatients : data.textHcp}
        textBtn={data.textBtn}
        variant={variant}
        handleBannerClose={handleBannerClose}
        bannerClose={bannerClose}
      /> */}

      <ExternalLinksPopup />
      {honestyPopupData.active && honestyPopupCookies !== 'true' && (
        <HonestyPopup pageUrl={pageUrl} />
      )}

      {!!formPopupData.isExist && !hcpFormAcceptedPage && (
        <HcpFormPopup
          open={checkHcpForm}
          handleClose={() => setCheckHcpForm(false)}
          formPopupData={formPopupData}
        />
      )}
    </PageWrapper>
  );
};

export default Page;
