import get from 'lodash.get';

import icon1 from '@assets/svg/icon_instagram.svg';
import icon2 from '@assets/svg/icon_linkedin.svg';
import icon3 from '@assets/svg/icon_facebook.svg';
import icon4 from '@assets/svg/XING.svg';
import icon5 from '@assets/svg/YT.svg';
import icon6 from '@assets/images/vk.png';
import map from '@assets/images/footer_map.png';

export const footerTransform = (query) => {
  const queryData = query.nodeQuery.entities[0];

  const data = {
    link_text: get(queryData, 'fieldText.value', ''),
    information: get(queryData, 'fieldCompanyInformation.processed', ''),
    social_media_text: get(queryData, 'fieldFollowUs.entity.fieldTitle', ''),
    chiesi_logo: get(queryData, 'fieldLogoSvg.entity.url', null),
    chiesi_logo_alt: get(queryData, 'fieldLogoSvg.alt', ''),
    chiesi_logo_title: get(queryData, 'fieldLogoSvg.title', ''),
    chiesi_logo_href: get(queryData, 'fieldLogoLink.url.path', ''),
    chiesi_map_href: get(queryData, 'fieldMapLink.url.path', ''),

    links: get(queryData, 'fieldFooterLink', []).map((link) => ({
      label: get(link, 'title', ''),
      href: get(link, 'url.path', ''),
    })),
    icons: [
      {
        icon: icon1,
        href: get(queryData, 'fieldFollowUs.entity.fieldInstagram', '/'),
      },
      {
        icon: icon2,
        href: get(queryData, 'fieldFollowUs.entity.fieldLinkedin', '/'),
      },
      {
        icon: icon3,
        href: get(queryData, 'fieldFollowUs.entity.fieldFacebook', '/'),
      },
      {
        icon: icon4,
        href: get(queryData, 'fieldFollowUs.entity.fieldXing', '/'),
      },
      {
        icon: icon5,
        href: get(queryData, 'fieldFollowUs.entity.fieldYoutube', '/'),
      },
      {
        icon: icon6,
        href: get(queryData, 'fieldFollowUs.entity.fieldVkontakte', '/'),
      },
    ],
    map: map,
    map_text: get(queryData, 'fieldMapText1', ''),
    map_text_inside: get(queryData, 'fieldMapText2', ''),
    map_text_bottom: get(queryData, 'fieldTextFooterInfo.value', ''),
    map_text_bottom2: get(queryData, 'fieldTextFooterInfo2.value', ''),
  };

  return data;
};
