import React from 'react';

// === Components === //
import Container from '@components/common/Container';
import FooterSocialIcons from '@components/layout/FooterSocialIcons';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions, mixins, respondTo } from '@styles';

// === Types === //
import { FooterData, PageType, Variant } from '@content/types/layout';
import FooterLinks from '@components/layout/FooterLinks';

const FooterTopWrapper = styled.div`
  border-bottom: 1px solid rgba(112, 112, 112, 0.4);
  display: flex;
  flex-direction: column;

  &.no-border {
    border: none;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  ${respondTo(
    breakpoints.lg,
    css`
      flex-direction: column;
      align-items: center;
    `
  )}
`;

const LogoWrapper = styled.a`
  width: auto;
  padding-right: 80px;
  ${respondTo(
    breakpoints.lg,
    css`
      padding-right: 0;
    `
  )};
`;

const LogoChiesi = styled.img`
  max-width: 158px;
  padding-top: 20px;
`;

const FooterLinksText = styled.p`
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 300;
  margin-left: 10px;
  color: ${colors.footerText};
  ${respondTo(
    breakpoints.lg,
    css`
      text-align: center;
    `
  )};
`;

const FooterInformationText = styled.p`
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 400;
  padding-top: 30px;
  margin-bottom: 35px;
  ${respondTo(
    breakpoints.lg,
    css`
      text-align: center;
      padding-top: 8px;
      margin-bottom: 28px;
    `
  )};
`;

interface FooterTopProps {
  data: FooterData;
  pageType: PageType;
  variant: Variant;
}

const FooterTop = ({ data, pageType, variant }: FooterTopProps) => {
  const currentLocal: string = (process.env.RETHINK_FABRY_LANGUAGE || '').toLowerCase();
  const excludeChiesiLink: string[] = ['se', 'fi'];

  return (
    <Container>
      <FooterTopWrapper className={`${pageType === 'start_page' ? 'no-border' : ''}`}>
        <FooterContent className="footer-content">
          {data.chiesi_logo && (
            <>
              {currentLocal &&
              currentLocal !== '' &&
              excludeChiesiLink.find((lang) => currentLocal === lang) ? (
                <LogoChiesi
                  src={data.chiesi_logo}
                  alt={data.chiesi_logo_alt}
                  title={data.chiesi_logo_title}
                />
              ) : (
                <LogoWrapper
                  href={data.chiesi_logo_href}
                  target="_blank"
                  className="logo-chiesi-wrapper"
                >
                  <LogoChiesi
                    src={data.chiesi_logo}
                    alt={data.chiesi_logo_alt}
                    title={data.chiesi_logo_title}
                  />
                </LogoWrapper>
              )}
            </>
          )}
          {pageType === 'start_page' && <FooterLinks links={data.links} variant={variant} />}
          <FooterSocialIcons data={data} />
        </FooterContent>
        <FooterInformationText className="footer-information-text">
          {data.information}
        </FooterInformationText>
      </FooterTopWrapper>
    </Container>
  );
};

export default FooterTop;
